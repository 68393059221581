import { FETCH_ACCOUNT_TYPES, FETCH_SERVICES, FETCH_TAGS } from "./actions"
import { ConstantsActionProps, ConstantsProps } from "types/constants"

export const initialConstantsState: ConstantsProps = {
  accountTypes: [],
  services: [],
  tags: []
}

const constants = (state = initialConstantsState, action: ConstantsActionProps) => {
  switch (action.type) {
    case FETCH_ACCOUNT_TYPES: {
      const accountTypes = action.payload?.accountTypes || []
      return {
        ...state,
        accountTypes
      }
    }
    case FETCH_SERVICES: {
      const services = action.payload?.services || []
      return {
        ...state,
        services
      }
    }
    case FETCH_TAGS: {
      const tags = action.payload?.tags || []
      return {
        ...state,
        tags
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default constants
