export const LOGIN = "@auth/LOGIN"
export const LOGOUT = "@auth/LOGOUT"
export const REGISTER = "@auth/REGISTER"
export const FETCH_USERS = "@users/FETCH_USERS"
export const FETCH_TRACKS = "@tracks/FETCH_TRACKS"
export const FETCH_BLACKLIST = "@tracks/FETCH_BLACKLIST"
export const FETCH_ARTISTS = "@artists/FETCH_ARTISTS"
export const FETCH_ALBUMS = "@albums/FETCH_ALBUMS"
export const UPDATE_ALBUM_TAKEDOWN_STATUS = "@albums/UPDATE_ALBUM_TAKEDOWN_STATUS"
export const FETCH_ARTIST_SOCIAL_LINKS = "@artists/FETCH_ARTIST_SOCIAL_LINKS"
export const FETCH_PLAYLISTS = "@playlists/FETCH_PLAYLISTS"
export const FETCH_PLAYLIST = "@playlists/FETCH_PLAYLIST"
export const UPDATE_PLAYLISTS_HOT_LIST = "@playlists/UPDATE_PLAYLISTS_HOT_LIST"
export const FETCH_COMPANIES = "@companies/FETCH_COMPANIES"
export const FETCH_COMPANY_BREAKDOWNS = "@companies/FETCH_COMPANY_BREAKDOWNS"
export const FETCH_CONFIRMED_OFFERS = "@offers/FETCH_CONFIRMED_OFFERS"
export const FETCH_SPOTIFY_ARTIST_REQUESTS = "@spotifyArtistRequests/FETCH_SPOTIFY_ARTIST_REQUESTS"
export const FETCH_ALLOWED_OFFER_LINKS = "@system/FETCH_ALLOWED_OFFER_LINKS"
export const SAVE_ALLOWED_OFFER_LINKS = "@system/SAVE_ALLOWED_OFFER_LINKS"
export const DELETE_ALLOWED_OFFER_LINKS = "@system/DELETE_ALLOWED_OFFER_LINKS"
export const FETCH_ACCOUNT_TYPES = "@constants/FETCH_ACCOUNT_TYPES"
export const FETCH_SERVICES = "@constants/FETCH_SERVICES"
export const FETCH_TAGS = "@constants/FETCH_TAGS"
export const CREATE_TAG = "@constants/CREATE_TAG"