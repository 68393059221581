import { User } from "./users"
import { Artist } from "./artists"
import { PaginatedData } from "./root"

export enum ConfirmedOfferTypes {
  ADVANCE = "advance",
  BUYOUT = "buyout"
}

export enum RoyaltyReportTypes {
  INDIVIDUAL = "individual",
  LABEL = "label"
}

export type RoyaltyReportRow = {
  distributor: string
  period: string
  platform: string
  transactionDate: string
  songTitle: string
  isrc: string
  created: string
  territory: string
  quantity: number
  currency: string
  segment: string
  segmentDescription: string
  netQuantity: number
  grossProgress: number
  netProgress: number
  splitRate: number
  channelId: string
  isVideo: boolean
}

export type RoyaltyReport = {
  user: User
  userId: number
  sheetId: string
  spotifyId: string
  artist: Artist
  progress: number
  validIsrc: boolean
  created: string
  distributor: string
  type: string
}

export interface ConfirmedOfferOption {
  id: number
  name: string
  value: string
  description: string
  multiplier: number
  type: string
}

export interface ConfirmedOffer {
  id: number
  spotifyId: string
  created: string
  advanceOfferUsd: number
  buyoutOfferUsd: number
  approved: boolean
  artist: Artist
  user: User
  userApproved: boolean
  selectedOption: ConfirmedOfferOption
  confirmedTotal: number
  type: string
}

export type ReportsContextType = {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
  fetchConfirmedOfferList: (
    orderBy: string,
    limit: number,
    offset: number,
    isPublic: boolean,
    artist?: string,
    username?: string,
    id?: number
  ) => Promise<void>
  updateConfirmedOffer: (id: number, approved: boolean, type: string, offerUsd?: number) => Promise<boolean>
  updateSongSelectionOffer: (confirmedOfferId: number, songSelectionId: number, offer: number) => Promise<boolean>
}

export interface ReportsProps {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
}

export interface ReportsActionProps {
  type: string
  payload?: ReportsProps
}
