import { createContext, ReactElement, useReducer } from "react"
import constantsReducer, { initialConstantsState } from "../store/reducers/constants"
import axios from "utils/axios"
import { CREATE_TAG, FETCH_ACCOUNT_TYPES, FETCH_SERVICES, FETCH_TAGS } from "../store/reducers/actions"
import { mapListToCamelCase } from "../utils/caseTransformation"
import { ConstantsContextType, Tag } from "types/constants"

const ConstantsContext = createContext<ConstantsContextType | null>(null)

export const ConstantsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(constantsReducer, initialConstantsState)

  const fetchAccountTypes = async () => {
    const response = await axios(`/constants/account_types/`, { method: "get" })

    dispatch({
      type: FETCH_ACCOUNT_TYPES,
      payload: { ...state, accountTypes: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchServices = async () => {
    const response = await axios(`/constants/services/`, { method: "get" })

    dispatch({
      type: FETCH_SERVICES,
      payload: { ...state, services: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchTags = async () => {
    const response = await axios(`/constants/tags/`, { method: "get" })

    dispatch({
      type: FETCH_TAGS,
      payload: { ...state, tags: mapListToCamelCase(response.data.results) }
    })
  }


  return (
    <ConstantsContext.Provider value={{ ...state, fetchAccountTypes, fetchServices, fetchTags }}>
      {children}
    </ConstantsContext.Provider>
  )
}

export default ConstantsContext
