import Hashids from "hashids"
import { format, parse, parseISO } from "date-fns"
import { BaseVariant, enqueueSnackbar } from "notistack"

export const getValueText = (value: number | string) => {
  if (typeof value === "string") {
    return value
  }
  return value > 999999 ? `${(value / 1000000).toFixed(1)}M` : value > 999 ? `${(value / 1000).toFixed(1)}K` : value
}

export const getFilterText = (filter: any) => {
  if (!filter) return ""
  const stringifiedFilters = Object.keys(filter)
    .filter((key) => filter[key] !== undefined)
    .map((key, idx) => `${key}=${filter[key]}${Object.keys(filter).length - 1 === idx ? "" : "&"}`)
  let endString = ""
  for (const filterString of stringifiedFilters) {
    endString = endString.concat(filterString)
  }

  if (!endString) {
    return ""
  }
  return `&${endString}`
}

export const getDate = (date: string) => {
  try {
    return format(parse(date, "yyyy-LL-dd", new Date()), "dd/LL/yyyy")
  } catch (e) {
    try {
      return format(parseISO(date), "dd/LL/yyyy")
    } catch (e) {
      return date
    }
  }
}

export const formatNumberComma = (text: string) => {
  const numericValue = text.replace(/\D/g, "")
  return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


export const toastMessage = (message: string, variant: BaseVariant) => {
  enqueueSnackbar(message, {
    autoHideDuration: 1500,
    anchorOrigin: { vertical: "top", horizontal: "right" },
    variant
  })
}

export const hashids = new Hashids("What comes first, goes last", 10)
