import { lazy } from "react"
import ScoutingMainLayout from "layout/scouting/MainLayout"
import ScoutingCommonLayout from "layout/scouting/CommonLayout"
import Loadable from "components/Loadable"
import AuthGuard from "utils/route-guard/AuthGuard"

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/error/404")))
const MaintenanceError500 = Loadable(lazy(() => import("pages/maintenance/error/500")))
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction/under-construction"))
)
const MaintenanceComingSoon = Loadable(lazy(() => import("pages/maintenance/coming-soon/coming-soon")))

const PlaylistPage = Loadable(lazy(() => import("pages/scouting/playlist/PlaylistPage")))
const PlaylistDetail = Loadable(lazy(() => import("pages/scouting/playlist/PlaylistDetail")))
const TracksPage = Loadable(lazy(() => import("pages/scouting/tracks/TracksPage")))
const ArtistsPage = Loadable(lazy(() => import("pages/scouting/artists/ArtistsPage")))
const SpotifyArtistRequestPage = Loadable(
  lazy(() => import("pages/scouting/spotify-artist-request/SpotifyArtistRequestPage"))
)
const ArtistsDetail = Loadable(lazy(() => import("pages/scouting/artists/ArtistDetail")))
const AlbumsPage = Loadable(lazy(() => import("pages/scouting/albums/AlbumsPage")))
const AlbumDetail = Loadable(lazy(() => import("pages/scouting/albums/AlbumDetail")))
const ArtistAnalysisPage = Loadable(lazy(() => import("pages/scouting/artists/analysis/ArtistAnalysisPage")))
const BlacklistPage = Loadable(lazy(() => import("pages/scouting/blacklist/BlacklistPage")))
const OffersReports = Loadable(lazy(() => import("pages/scouting/offers-reports/OffersReports")))
const ConfirmedOffersPage = Loadable(lazy(() => import("pages/scouting/confirmed-offers/ConfirmedOffersPage")))
const ConfirmedOfferDetail = Loadable(lazy(() => import("pages/scouting/confirmed-offers/detail/ConfirmedOfferDetail")))
const CompanyBreakdownPage = Loadable(lazy(() => import("pages/scouting/company-breakdown/CompanyBreakdownPage")))
const CompanyPage = Loadable(lazy(() => import("pages/scouting/company/CompanyPage")))
const SystemSettings = Loadable(lazy(() => import("pages/scouting/admin/system/SystemSettings")))
const SchedulerLog = Loadable(lazy(() => import("pages/scouting/admin/scheduler/SchedulerLog")))
const AllowedOfferLinks = Loadable(lazy(() => import("pages/scouting/admin/allowed-offer-links/AllowedOfferLinks")))
const UsersPage = Loadable(lazy(() => import("pages/scouting/admin/user-list/UsersPage")))
const TagsPage = Loadable(lazy(() => import("pages/scouting/admin/tags/TagsPage")))

const ScoutingRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <ScoutingMainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "home",
          element: <div>WELCOME TO SCOUTING</div>
        },
        {
          path: "playlist",
          element: <PlaylistPage />
        },
        {
          path: "tracks",
          element: <TracksPage showFilter={true} showLabelColumns={true} showOwnerships={false} />
        },
        {
          path: "artists",
          element: <ArtistsPage />
        },
        {
          path: "playlist/:playlistId",
          element: <PlaylistDetail />
        },
        {
          path: "artists/:artistIdParam",
          element: <ArtistsDetail />
        },
        {
          path: "artists/:artistId/analysis/:analysisId",
          element: <ArtistAnalysisPage />
        },
        {
          path: "releases",
          element: <AlbumsPage artistId={0} />
        },
        {
          path: "releases/:albumId",
          element: <AlbumDetail />
        },
        {
          path: "blacklist",
          element: <BlacklistPage />
        },
        {
          path: "offers-reports",
          element: <OffersReports />
        },
        {
          path: "spotify-artist-request",
          element: <SpotifyArtistRequestPage />
        },
        {
          path: "confirmed-offers",
          element: <ConfirmedOffersPage />
        },
        {
          path: "confirmed-offer/:confirmedOfferId",
          element: <ConfirmedOfferDetail isPublic={false} />
        },
        {
          path: "company",
          element: <CompanyPage />
        },
        {
          path: "company-breakdown",
          element: <CompanyBreakdownPage blackListPage={false} />
        },
        {
          path: "admin/system-settings",
          element: <SystemSettings />
        },
        {
          path: "admin/scheduler-logs",
          element: <SchedulerLog />
        },
        {
          path: "admin/allowed-offer-links",
          element: <AllowedOfferLinks />
        },
        {
          path: "admin/tags",
          element: <TagsPage />
        },
        {
          path: "admin/users",
          element: <UsersPage />
        }
      ]
    },
    {
      path: "/maintenance",
      element: <ScoutingCommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />
        },
        {
          path: "500",
          element: <MaintenanceError500 />
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
}

export default ScoutingRoutes
